import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const animations = () => {

    const parallax = () => {

        console.log('test');
        
        //function for moving diamonds

        const tl = gsap.timeline({});

        const item = document.querySelectorAll('.js-move-diamond');
        const parent = document.querySelector('.js-move-diamond-container');
        
        gsap.utils.toArray(item).forEach(layer => {
            let movement = layer.getAttribute('move-dist') ? layer.getAttribute('move-dist') : -100;
            tl.to(layer, {y: movement}, 0)
        });

        ScrollTrigger.create({
            trigger: parent,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true,
            animation: tl
        });
        if(window.matchMedia("(min-width: 600px").matches) {
            ScrollTrigger.refresh() 
        }
           
    }

    const movePanels = () => {

        const tl = gsap.timeline({});

        const item = document.querySelectorAll('.js-move-panels');
        const parent = document.querySelector('.js-move-panels-container');
        let i = 0;
        gsap.utils.toArray(item).forEach(layer => {

            if(i !== 2) {
                tl.to(layer, {yPercent: 10, xPercent: -10}, 0)
            }
            else {
                tl.to(layer, {yPercent: -10, xPercent: 10}, 0)
            }
            i++;
           
        });

        ScrollTrigger.create({
            trigger: parent,
            start: 'top bottom',
            endTrigger: '.block--quote-slider',
            scrub: true,
            animation: tl
        });
        if(window.matchMedia("(min-width: 600px").matches) {

            ScrollTrigger.refresh() 

        }
           
    }

    const fadeIn = () => {
        const section = document.querySelectorAll('.js-fade-in-container');
        if(window.matchMedia("(min-width: 600px").matches) {
        gsap.utils.toArray(section).forEach(layer => {
            const animation = gsap.fromTo(layer, {opacity: 0.1}, {opacity: 1, duration: 0.3});

            //const tl = gsap.timeline({});
            // tl.fromTo(layer, {opacity: 0}, {opacity: 1, duration: 0.3});
            // tl.to(layer, {opacity: 0})
            
            ScrollTrigger.create({
                trigger: layer,
                start: 'top 60%', 
                end: 'top 10%',
                scrub: true,
                animation: animation
            });
        });
        }
    }

    const backgroundChange = () => {

    //     const item = document.querySelector('.home');
    //     const target = document.querySelector('.block--image-gallery-text');
    //     const prev = target.previousElementSibling;
    //     let i = 0;

    //     const tl = gsap.timeline({});
    //     tl.to(item, {backgroundColor: '#78bde8'})
    //     tl.to(prev, {opacity: 0}, 0)
    //     tl.to(item, {backgroundColor: '#fff',})
        
       
    //     ScrollTrigger.create({
    //         trigger: target,
    //         start: 'top 50%',
    //         end: 'bottom top',
    //         scrub: true,
    //         animation: tl
    //     });
            
    }

    const backgroundChange2 = () => {

        // const item = document.querySelector('.home');
        // const target = document.querySelector('.block--quote-slider');
        // let i = 0;
        
        // const animation = gsap.to(item, {
        //     backgroundColor: '#fff',
        //     duration: 0.5,
        //     immediateRender: false,
        //     paused: true
        //   });

        // ScrollTrigger.create({
        //     trigger: target,
        //     start: 'top bottom',
        //     end: 'top 25%',
        //     scrub: true,
        //     animation: animation
        // });
            
    }

    const bannerVideo = () => {

        const homeVideoTl = gsap.timeline({
			paused: false,
			onStart: () => { console.log('starting') ;}
		});
		let videoBanner = $('.js-animate-video');		
		homeVideoTl.to(videoBanner, {
		autoAlpha: 1,
        duration: 0.8
		}, "");
        
    }

    return {parallax, movePanels, backgroundChange, fadeIn, bannerVideo};
}

export default animations;